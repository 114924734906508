$(function () {

    /*アラートライブラリ*/
    //window.Swal = require('sweetalert2');
    
    // パスセット
    //window.mypath = window.location.protocol + '//' + window.location.host;

    // Ajaxセットアップ
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    //'object-fit: cover'; /*IE対策*/
    objectFitImages();
    
});

$(window).on('load', function () {
    // ローディング画面終了
    setTimeout(function () {
        $('#loading').fadeOut();
    }, 500);
});

$(function () {

    // モバイルメニュー　ボタン
    $('#mobile_menu_btn').on("click", function () {
        openMobileMenu();
        //stopScroll();
    });

    // モバイルメニュー　閉じるボタン
    $('#close_btn').on("click", function () {
        closeMobileMenu();
        //startScroll();
    });

    changeMenuStyle();

    // ウィンドウサイズを変更したら
    $(window).on("resize", function () {
        changeMenuStyle();
    });

    // スクロールしたら
    $(document).on("scroll", function () {
        changeMenuStyle();
    });
});

function changeMenuStyle() {
    if ($(window).width() > 992) {
        $('#menu_space').removeClass('mobileMenu_open');
        $('#menu_space').removeClass('mobileMenu_close');
        $('#mobile_menu_btn').css({
            'display': 'none'
        });
        $('#close_btn').css({
            'display': 'none'
        });
        $('.menu').css({
            'display': 'block'
        });
    } else {
        $('#menu_space').removeClass('mobileMenu_open');
        $('#menu_space').removeClass('mobileMenu_close');
        $('#mobile_menu_btn').css({
            'display': 'block'
        });
        $('#close_btn').css({
            'display': 'none'
        });
        $('.menu').css({
            'display': 'none'
        });
    }
}

function openMobileMenu() {
    $('#menu_space').addClass('mobileMenu_open');
    $('#menu_space').removeClass('mobileMenu_close');
    $('#mobile_menu_btn').css({
        'display': 'none'
    });
    $('#close_btn').css({
        'display': 'inline-block'
    });
    $('.menu').fadeIn();
}
function closeMobileMenu() {
    $('#menu_space').addClass('mobileMenu_close');
    $('#menu_space').removeClass('mobileMenu_open');
    $('#mobile_menu_btn').css({
        'display': 'inline-block'
    });
    $('#close_btn').css({
        'display': 'none'
    });
    $('.menu').css({
        'display': 'none'
    });
}

// オーバーレイ（プログレスバー）表示時のスクロール禁止
window.posi = 0;
function stopScroll() {
    posi = $(window).scrollTop();
    $('#app').css({
        position: 'fixed',
        top: -1 * posi,
        width: '100%'
    });
}
function startScroll() {
    $('#app').attr('style', '');
    $('html, body').prop({ scrollTop: posi });
}