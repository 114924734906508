import * as common from './module/common';

$(function () {

    // init Swiper:
    var slide_top = new Swiper('.slide_top', {
        effect: 'fade',
        slidesPerView: 1,
        centeredSlides: true,
        //autoHeight: true,
        spaceBetween: 0,
        watchOverflow: true,
        speed: 3000,
        initialSlide: 0,
        loop: true,

        autoplay: {
            delay: 8000,
        },
    });

    // init Swiper:
    var slide_product = new Swiper('.slide_product', {
        slidesPerView: 2,
        centeredSlides: true,
        //autoHeight: true,
        spaceBetween: 20,
        watchOverflow: true,
        freeMode: true,
        autoplay: {
            delay: 0,
            waitForTransition: true,
            disableOnInteraction: false,
        },
        speed: 5000,
        loop: true,
        breakpoints: {
            1200: {
                slidesPerView: 3,
                spaceBetween: 120,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 100,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 80,
            },
            576: {
                slidesPerView: 2,
                spaceBetween: 30
            }
        }
    });
});

$(function () {

    resizeContainer();
    toggleTopLogo();

    // ウィンドウサイズを変更したら
    $(window).on("resize", function () {
        resizeContainer();
        toggleMenuLogo();
        toggleTopLogo();
    });

    // スクロールしたら
    $(document).on("scroll", function () {
        toggleTopLogo();
        toggleMenuLogo();
    });
});

function resizeContainer() {
    // cssの設定取得
    let coverItem_min_height = parseInt($('.coverItem').css('min-height').replace('px', ''));
    let coverItem_max_height = parseInt($('.coverItem').css('max-height').replace('px', ''));
    let menu_space_height = parseInt($('#menu_space').css('height').replace('px', ''));
    
    // トップ画像の高さ（swiper）設定
    if ($(window).width() > 768) {
        if ($(window).height() > coverItem_min_height + menu_space_height) {
            if ($(window).height() > coverItem_max_height + menu_space_height) {
                $('.coverItem_main').css({
                    'height': coverItem_max_height,
                });
            } else {
                $('.coverItem_main').css({
                    'height': $(window).height() - menu_space_height,
                });
            }
        } else {
            $('.coverItem_main').css({
                'height': coverItem_min_height,
            });
        }
    } else {
        $('.coverItem_main').css({
            'height': 300,
        });
    }
}

function toggleTopLogo() {

    if ($(document).scrollTop() > ($(window).height() - 300)) {
        $('.coverItem_top').fadeOut();
    } else {
        $('.coverItem_top').fadeIn();
    }
}

function toggleMenuLogo() {

    if ($(document).scrollTop() > ($(window).height() - 100) || $(window).width() < 992) {
        $('.menu_logo_space').css({
            'display': 'flex',
        });
        $('#menu_space').css({
            'position': 'fixed',
            'box-shadow': '0px 2px 2px rgba(0,0,0,0.1)',
        });
    } else {
        $('.menu_logo_space').css({
            'display': 'none',
        });
        $('#menu_space').css({
            'position': 'absolute',
            'box-shadow': '0px 0px 0px rgba(0,0,0,0.1)',
        });
    }
}